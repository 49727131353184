@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  margin: 0;
  width: 100%;
  height: 100%;
}

.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #a7a295 #f1f1ee;
}

@font-face {
  font-family: "Graphik-Regular";
  src: url("../src/fonts/Graphik-Regular.eot");
  src: local("☺"), url("../src/fonts/Graphik-Regular.woff") format("woff"),
    url("../src/fonts/Graphik-Regular.ttf") format("truetype"),
    url("../src/fonts/Graphik-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Graphik-Medium";
  src: url("../src/fonts/Graphik-Medium.eot");
  src: local("☺"), url("../src/fonts/Graphik-Medium.woff") format("woff"),
    url("../src/fonts/Graphik-Medium.ttf") format("truetype"),
    url("../src/fonts/Graphik-Medium.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Graphik-Semibold";
  src: url("../src/fonts/Graphik-Semibold.eot");
  src: local("☺"), url("../src/fonts/Graphik-Semibold.woff") format("woff"),
    url("../src/fonts/Graphik-Semibold.ttf") format("truetype"),
    url("../src/fonts/Graphik-Semibold.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
